import { createAction } from 'redux-act';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
const NAME = 'default service/';
export function makeBaseActions(name) {
    const setRuleWhen = createAction(`${name}set default svc rule when`);
    const setRuleAction = createAction(`${name}set default svc rule action`);
    const discardChanges = createAction(`${name}discard default svc rule changes`);
    const [loadRulesActions, loadRules] = createActionsAndExecutor(`${name}load default svc rules`, (client, { groupId, appId, svcId }) => () => client.apps(groupId).app(appId).services().service(svcId).rules().list());
    const [loadRuleActions, loadRule] = createActionsAndExecutor(`${name}load default svc rule`, (client, { groupId, appId, svcId, ruleId }) => () => client.apps(groupId).app(appId).services().service(svcId).rules().rule(ruleId).get());
    const [addRuleActions, addRule] = createActionsAndExecutor(`${name}add default svc rule`, (client, { groupId, appId, svcId, rule }) => () => client.apps(groupId).app(appId).services().service(svcId).rules().create(rule));
    const [updateRuleActions, updateRule] = createActionsAndExecutor(`${name}update default svc rule`, (client, { groupId, appId, svcId, ruleId, rule }) => () => client.apps(groupId).app(appId).services().service(svcId).rules().rule(ruleId).update(rule));
    const [deleteRuleActions, deleteRule] = createActionsAndExecutor(`${name}delete default svc rule`, (client, { groupId, appId, svcId, ruleId }) => () => client.apps(groupId).app(appId).services().service(svcId).rules().rule(ruleId).remove());
    return {
        loadRulesActions,
        loadRuleActions,
        addRuleActions,
        updateRuleActions,
        deleteRuleActions,
        setRuleWhen,
        setRuleAction,
        discardChanges,
        loadRules,
        loadRule,
        addRule,
        rulesEditMapper: {
            mapDispatchToProps: (dispatch, ownProps) => {
                const groupId = ownProps.app.groupId;
                const appId = ownProps.app.id;
                const svcId = ownProps.service.id;
                return {
                    loadRule: (ruleId) => dispatch(loadRule({ groupId, appId, svcId, ruleId })),
                    loadRules: () => dispatch(loadRules({ groupId, appId, svcId })),
                    addRule: (rule) => dispatch(addRule({ groupId, appId, svcId, rule })),
                    deleteRule: (ruleId) => dispatch(deleteRule({ groupId, appId, svcId, ruleId })).then(() => loadRules({ groupId, appId, svcId })),
                    updateRule: (ruleId, rule) => dispatch(updateRule({
                        groupId,
                        appId,
                        svcId,
                        ruleId,
                        rule,
                    })),
                };
            },
        },
    };
}
const defaultActions = makeBaseActions(NAME);
export default defaultActions;
