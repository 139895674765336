import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useCallback, useContext, useState } from 'react';
import { connect } from 'react-redux';
import usePoller from 'baas-ui/common/hooks/use-poller';
import * as actions from 'baas-ui/deploy/actions';
import ConfirmDeploymentDiff from 'baas-ui/deploy/confirm-deployment-diff';
import * as sagas from 'baas-ui/deploy/sagas';
import { getAppState, getDeploymentState } from 'baas-ui/selectors';
export var DeployStatus;
(function (DeployStatus) {
    DeployStatus["Deploying"] = "deploying";
    DeployStatus["Drafting"] = "draft";
    DeployStatus["Creating"] = "creating";
    DeployStatus["Discarding"] = "discarding";
    DeployStatus["Error"] = "error";
    DeployStatus["Failure"] = "failure";
    DeployStatus["None"] = "none";
    DeployStatus["Successful"] = "successful";
    DeployStatus["SuccessfulWithWarning"] = "successfulWithWarning";
})(DeployStatus || (DeployStatus = {}));
export const DeployContext = createContext({
    setCreatingDraft: () => { },
    setDisableBanner: () => { },
    openDeployDraftModal: () => { },
    pushApp: () => Promise.resolve(),
    status: DeployStatus.None,
    message: '',
    deployedAt: undefined,
    disableBanner: false,
});
export function DeployContextProviderComponent({ autoDeployEnabled, children, deployDraft, discardDraft, draft, latestDeployment, loadDeployHistory, loadDrafts, loadDeployConfig, loadLatestDeployment, pushApp, }) {
    const poller = usePoller();
    const [message, setMessage] = useState('');
    const [deployStatus, setDeployStatus] = useState(DeployStatus.None);
    const [disableBanner, setDisableBanner] = useState(false);
    const [confirmDiffModalProps, setConfirmDiffModalProps] = useState({
        isOpen: false,
        onCancel: () => { },
        onDiscard: () => { },
        onDeploy: () => { },
    });
    const setCreatingDraft = (isCreatingDraft, creatingDraftMessage = '') => {
        if (isCreatingDraft) {
            setDeployStatus(DeployStatus.Creating);
        }
        else {
            setDeployStatus(draft ? DeployStatus.Drafting : DeployStatus.None);
        }
        setMessage(creatingDraftMessage);
    };
    const handlePushApp = async () => {
        setDeployStatus(DeployStatus.Deploying);
        try {
            await pushApp();
            poller.start(loadLatestDeployment, 2000);
        }
        catch (e) {
            setMessage(e?.message);
            setDeployStatus(DeployStatus.Error);
        }
    };
    const handleOpenDeployDraftModal = useCallback(() => {
        setConfirmDiffModalProps({
            isOpen: true,
            onCancel: () => setConfirmDiffModalProps({
                ...confirmDiffModalProps,
                isOpen: false,
            }),
            onDiscard: () => {
                setDeployStatus(DeployStatus.Discarding);
                setConfirmDiffModalProps({
                    ...confirmDiffModalProps,
                    isOpen: false,
                });
                if (draft) {
                    discardDraft(draft.id);
                }
            },
            onDeploy: (deploymentName) => {
                setConfirmDiffModalProps({
                    ...confirmDiffModalProps,
                    isOpen: false,
                });
                if (draft) {
                    setDeployStatus(DeployStatus.Deploying);
                    deployDraft(autoDeployEnabled, draft.id, deploymentName)
                        .then(() => {
                        poller.start(loadLatestDeployment, 2000);
                    })
                        .catch((error) => {
                        setMessage(error.message);
                        setDeployStatus(DeployStatus.Error);
                    });
                }
            },
        });
    }, [draft, autoDeployEnabled]);
    React.useEffect(() => {
        loadDrafts();
        loadDeployConfig();
        loadLatestDeployment();
    }, []);
    React.useEffect(() => {
        if (deployStatus === DeployStatus.Deploying) {
            switch (latestDeployment?.status) {
                case 'successful':
                    setDeployStatus(DeployStatus.Successful);
                    loadDeployHistory();
                    poller.stop();
                    break;
                case 'successfulWithWarning':
                    setDeployStatus(DeployStatus.SuccessfulWithWarning);
                    loadDeployHistory();
                    setMessage(latestDeployment.statusErrorMessage);
                    poller.stop();
                    break;
                case 'failed':
                    setDeployStatus(DeployStatus.Failure);
                    setMessage(latestDeployment.statusErrorMessage);
                    poller.stop();
                    break;
                default:
            }
        }
        else if (latestDeployment?.status === 'created' || latestDeployment?.status === 'pending') {
            setDeployStatus(DeployStatus.Deploying);
            poller.start(loadLatestDeployment, 2000);
        }
    }, [latestDeployment]);
    React.useEffect(() => {
        if (!draft) {
            if (deployStatus === DeployStatus.Deploying) {
                setDeployStatus(DeployStatus.Successful);
            }
            else {
                setDeployStatus(DeployStatus.None);
            }
        }
        else if (deployStatus === DeployStatus.None || latestDeployment?.draftId !== draft.id) {
            setDeployStatus(DeployStatus.Drafting);
        }
    }, [draft]);
    return (_jsxs(DeployContext.Provider
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    , { 
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value: {
            setCreatingDraft,
            setDisableBanner,
            openDeployDraftModal: handleOpenDeployDraftModal,
            pushApp: handlePushApp,
            status: deployStatus,
            message,
            deployedAt: latestDeployment?.deployedAt,
            disableBanner,
        }, children: [confirmDiffModalProps.isOpen && _jsx(ConfirmDeploymentDiff, { ...confirmDiffModalProps }), children] }));
}
const mapStateToProps = (state) => {
    const { app } = getAppState(state);
    const { draft, latestDeployment, deployConfig: { automaticDeploymentConfig: { enabled }, }, } = getDeploymentState(state);
    return {
        appId: app.id,
        draft,
        groupId: app.groupId,
        latestDeployment,
        autoDeployEnabled: enabled,
    };
};
const mapDispatchToProps = (dispatch) => ({
    deployDraft: (groupId, appId, draftId, pushToGithub, deploymentName) => dispatch(sagas.deployDraft({ groupId, appId, draftId, pushToGithub, deploymentName })),
    discardDraft: (groupId, appId, draftId) => dispatch(sagas.discardDraft(groupId, appId, draftId)),
    loadDeployHistory: (groupId, appId) => dispatch(actions.loadDeployHistory({ groupId, appId, filter: { before: Date.now() } })),
    loadDrafts: (groupId, appId) => dispatch(actions.loadDrafts({ groupId, appId })),
    loadDeployConfig: (groupId, appId) => dispatch(actions.loadDeployConfig({ groupId, appId })),
    loadLatestDeployment: (groupId, appId) => dispatch(actions.loadLatestDeployment({ groupId, appId })),
    pushApp: (groupId, appId) => dispatch(actions.pushApp({ groupId, appId })),
});
const mergeProps = ({ appId, groupId, ...otherStateProps }, dispatchProps, ownProps) => ({
    ...ownProps,
    ...otherStateProps,
    loadDrafts: () => dispatchProps.loadDrafts(groupId, appId),
    deployDraft: (pushToGithub, draftId, deploymentName) => dispatchProps.deployDraft(groupId, appId, draftId, pushToGithub, deploymentName),
    loadDeployConfig: () => dispatchProps.loadDeployConfig(groupId, appId).catch(() => { }),
    discardDraft: (draftId) => dispatchProps.discardDraft(groupId, appId, draftId),
    loadDeployHistory: () => dispatchProps.loadDeployHistory(groupId, appId).catch(() => { }),
    loadLatestDeployment: () => dispatchProps.loadLatestDeployment(groupId, appId),
    pushApp: () => dispatchProps.pushApp(groupId, appId),
});
export const DeployContextProvider = connect(mapStateToProps, mapDispatchToProps, mergeProps)(DeployContextProviderComponent);
export function useDeployContext() {
    const value = useContext(DeployContext);
    if (!value) {
        throw new Error('useDeployContext must be used within a DeployContextProvider');
    }
    return value;
}
