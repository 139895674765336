import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { WebhookConversionStrategy } from 'admin-sdk';
const NAME = 'endpoints/';
// endpoint actions
export const [loadEndpointsActions, loadEndpoints] = createActionsAndExecutor(`${NAME}load`, (client, { groupId, appId }) => () => client.apps(groupId).app(appId).endpoints().list());
export const [fetchEndpointActions, fetchEndpoint] = createActionsAndExecutor(`${NAME}fetch`, (client, { groupId, appId, endpointId }) => () => client.apps(groupId).app(appId).endpoints().endpoint(endpointId).get());
export const [createEndpointActions, createEndpoint] = createActionsAndExecutor(`${NAME}create`, (client, { groupId, appId, newEndpoint }) => () => client.apps(groupId).app(appId).endpoints().create(newEndpoint));
export const [removeEndpointActions, removeEndpoint] = createActionsAndExecutor(`${NAME}delete`, (client, { groupId, appId, endpointId }) => () => client.apps(groupId).app(appId).endpoints().endpoint(endpointId).remove());
export const [updateEndpointActions, updateEndpoint] = createActionsAndExecutor(`${NAME}update`, (client, { groupId, appId, endpointId, endpointToUpdate }) => () => client.apps(groupId).app(appId).endpoints().endpoint(endpointId).update(endpointToUpdate));
export const [duplicateEndpointActions, duplicateEndpoint] = createActionsAndExecutor(`${NAME}duplicate`, (client, { groupId, appId, endpointId }) => () => client.apps(groupId).app(appId).endpoints().endpoint(endpointId).duplicate());
export const [convertEndpointsActions, convertEndpoints] = createActionsAndExecutor(`${NAME}convert`, (client, { groupId, appId, strategy }) => () => client.apps(groupId).app(appId).endpoints().convert({ strategy }));
export const [convertEndpointsDryRunAction, convertEndpointsDryRun] = createActionsAndExecutor(`${NAME}convert dry run`, (client, { groupId, appId }) => () => client.apps(groupId).app(appId).endpoints().convert({ strategy: WebhookConversionStrategy.DryRun }));
export const asyncEditRcvActions = [
    convertEndpointsActions.rcv,
    createEndpointActions.rcv,
    removeEndpointActions.rcv,
    updateEndpointActions.rcv,
    duplicateEndpointActions.rcv,
];
