import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@leafygreen-ui/button';
import { Body, H3 } from '@leafygreen-ui/typography';
import BrandIcon from '@mongodb-js/brand-icons';
import DocLink from 'baas-ui/common/components/doc-link';
import { docLinks } from 'baas-ui/common/links';
import { getAppState } from 'baas-ui/selectors';
import { Track } from 'baas-ui/tracking';
import urls from 'baas-ui/urls';
export var TestSelector;
(function (TestSelector) {
    TestSelector["EmptyState"] = "link-data-source-empty-state";
})(TestSelector || (TestSelector = {}));
const LinkDataSourceEmptyState = ({ clustersUrlRoot, description, title, actionTitle }) => (_jsxs("div", { className: "empty-state", "data-cy": TestSelector.EmptyState, "data-testid": TestSelector.EmptyState, children: [_jsx(BrandIcon, { iconName: 'Technical_REALM_Database', height: 72, width: 72 }), _jsx(H3, { children: title }), _jsx(Body, { children: description }), _jsx(Track, { event: "CLUSTER_LINK.LINK_CLUSTER_CLICKED", children: _jsx(Link, { to: clustersUrlRoot.new(), children: _jsx(Button, { variant: "primary", "data-cy": "data-source-empty-state-link-button", children: actionTitle }) }) }), _jsx(DocLink, { href: docLinks.General.AtlasOverview, children: "Learn more about Atlas Clusters and your app" })] }));
const mapStateToProps = (state) => {
    const { app: { id: appId, groupId }, } = getAppState(state);
    const clustersUrlRoot = urls.groups().group(groupId).apps().app(appId).clusters();
    return { clustersUrlRoot };
};
export { LinkDataSourceEmptyState as LinkDataSourceEmptyStateComponent };
export default connect(mapStateToProps)(LinkDataSourceEmptyState);
