export const MAX_PENDING_USER_COUNT = 50;
export const STATUS_ENABLED = 'enabled';
export const STATUS_DISABLED = 'disabled';
export const SORT_ID = '_id';
export const SORT_ENABLED = 'status';
export const SORT_CREATED = 'created_date';
export const SORT_LAST_AUTH = 'last_authentication_date';
const USER_FIELD_CREATED_DATE = 'creationDate';
const USER_FIELD_LAST_AUTH_DATE = 'lastAuthenticationDate';
const USER_FIELD_DISABLED = 'disabled';
export const USERDOC_SORT_FIELDS = {
    // Maps the values for the "sort" query param to the field names in the user
    // doc to where their values can be found.
    [SORT_ENABLED]: USER_FIELD_DISABLED,
    [SORT_CREATED]: USER_FIELD_CREATED_DATE,
    [SORT_LAST_AUTH]: USER_FIELD_LAST_AUTH_DATE,
};
export var TABLE_VIEW;
(function (TABLE_VIEW) {
    TABLE_VIEW["PENDING"] = "PENDING";
    TABLE_VIEW["CONFIRMED"] = "CONFIRMED";
})(TABLE_VIEW || (TABLE_VIEW = {}));
