/* eslint-disable @typescript-eslint/ban-types,camelcase */
export var ReadPreference;
(function (ReadPreference) {
    ReadPreference["Primary"] = "primary";
    ReadPreference["PrimaryPreferred"] = "primaryPreferred";
    ReadPreference["Secondary"] = "secondary";
    ReadPreference["SecondaryPreferred"] = "secondaryPreferred";
    ReadPreference["Nearest"] = "nearest";
})(ReadPreference || (ReadPreference = {}));
export var MongoServiceStatus;
(function (MongoServiceStatus) {
    MongoServiceStatus["Unlinked"] = "UNLINKED";
    MongoServiceStatus["Linking"] = "LINKING";
    MongoServiceStatus["Linked"] = "LINKED";
    MongoServiceStatus["Error"] = "ERROR";
})(MongoServiceStatus || (MongoServiceStatus = {}));
export var ClusterType;
(function (ClusterType) {
    ClusterType["Atlas"] = "atlas";
    ClusterType["Serverless"] = "serverless";
})(ClusterType || (ClusterType = {}));
