import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Icon, { Size } from '@leafygreen-ui/icon';
import { StatusBanner, Variant } from 'baas-ui/common/components/status';
import usePoller from 'baas-ui/common/hooks/use-poller';
import { getAppState, getSyncState } from 'baas-ui/selectors';
import { loadSyncMigrationStatus } from 'baas-ui/sync/actions';
import { STATUS_TYPE_SYNC_MIGRATION } from 'baas-ui/sync/constants';
import { triggerSyncMigrationAction } from 'baas-ui/sync/sagas';
import { SyncMigrationAction } from 'admin-sdk';
const BannerBody = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.leafygreen.spacing[2],
}));
export var TestSelector;
(function (TestSelector) {
    TestSelector["Banner"] = "sync-migration-banner";
    TestSelector["WarningIcon"] = "sync-migration-warning-icon";
    TestSelector["LoadingSpinner"] = "sync-migration-banner-spinner";
    TestSelector["Message"] = "sync-migration-banner-message";
    TestSelector["CancelButton"] = "sync-migration-cancel-button";
})(TestSelector || (TestSelector = {}));
export const SyncMigrationBanner = ({ doPolling, migrationStatus, loadMigrationStatus, cancelMigration }) => {
    const poller = usePoller('syncMigrationStatusPoller');
    React.useEffect(() => {
        loadMigrationStatus();
    }, []);
    React.useEffect(() => {
        if (doPolling) {
            poller.start(loadMigrationStatus, 3e3);
        }
        return () => poller.stop();
    }, [doPolling]);
    if (!migrationStatus || (!migrationStatus.statusMessage && !migrationStatus.errorMessage)) {
        return null;
    }
    const bannerBody = migrationStatus.errorMessage ? (_jsxs(BannerBody, { children: [_jsx(Icon, { glyph: "Warning", size: Size.Small, "data-testid": TestSelector.WarningIcon }), _jsxs("div", { "data-testid": TestSelector.Message, children: [_jsx("div", { children: migrationStatus.errorMessage }), _jsx("div", { children: "Please try the migration process again" })] })] })) : (_jsxs(BannerBody, { children: [_jsx("div", { children: _jsx(FontAwesome, { name: "circle-o-notch", spin: true, "data-testid": TestSelector.LoadingSpinner }) }), _jsxs("div", { "data-testid": TestSelector.Message, children: [_jsx("div", { children: migrationStatus.statusMessage }), migrationStatus.isCancelable && (_jsxs("div", { children: ["No changes can be saved until migration is complete.", '  ', _jsx("span", { onClick: () => cancelMigration(), style: { textDecoration: 'underline', cursor: 'pointer' }, "data-testid": TestSelector.CancelButton, "data-cy": TestSelector.CancelButton, children: "Stop Migration" })] }))] })] }));
    return (_jsx(StatusBanner, { "data-testid": TestSelector.Banner, "data-cy": TestSelector.Banner, message: bannerBody, variant: migrationStatus.errorMessage ? Variant.Error : Variant.Info, statusType: STATUS_TYPE_SYNC_MIGRATION, statusId: "sync-migration-status-banner" }));
};
const mapStateToProps = (state) => {
    const { app: { id: appId, groupId }, } = getAppState(state);
    const { config: { serviceId }, migration: { pollCount, status }, } = getSyncState(state);
    return {
        groupId,
        appId,
        serviceId: serviceId || '',
        doPolling: pollCount > 0,
        migrationStatus: status,
    };
};
const mapDispatchToProps = (dispatch) => ({
    loadMigrationStatus: (groupId, appId) => () => dispatch(loadSyncMigrationStatus({ groupId, appId })).catch(() => { }),
    cancelMigration: (groupId, appId, serviceId) => () => dispatch(triggerSyncMigrationAction(groupId, appId, serviceId, SyncMigrationAction.Cancel)),
});
const mergeProps = ({ groupId, appId, serviceId, ...otherStateProps }, { loadMigrationStatus, cancelMigration }) => ({
    ...otherStateProps,
    loadMigrationStatus: loadMigrationStatus(groupId, appId),
    cancelMigration: cancelMigration(groupId, appId, serviceId),
});
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SyncMigrationBanner);
