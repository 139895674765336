import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Button from '@leafygreen-ui/button';
import { Body } from '@leafygreen-ui/typography';
import { Track } from 'baas-ui/tracking';
import './github-authorization-button.less';
const authButton = 'github-authorization-button';
export var TestSelector;
(function (TestSelector) {
    TestSelector["AuthorizeGitHub"] = "authorize-github";
    TestSelector["GithubUsername"] = "github-username";
    TestSelector["GithubAuthError"] = "github-auth-error";
})(TestSelector || (TestSelector = {}));
function GithubAuthorizationButton({ isAuthorized, hasAuthorizationError, buttonText, disabled, onGithubAuthClick, }) {
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: `${authButton}-row`, children: _jsx(Track, { event: "DEPLOY_CONFIGURATION.GITHUB_APP_AUTHORIZED_CLICKED", children: _jsx(Button, { size: "xsmall", "data-test-selector": TestSelector.AuthorizeGitHub, "data-testid": TestSelector.AuthorizeGitHub, onClick: onGithubAuthClick, disabled: disabled, children: _jsx("span", { className: `${authButton}-row-text`, children: buttonText || (isAuthorized ? 'Unlink' : 'Authorize') }) }) }) }), hasAuthorizationError && (_jsx(Body, { "data-test-selector": TestSelector.GithubAuthError, className: `${authButton}-error`, children: `Authorization unsuccessful. Ensure you've installed App Services as a GitHub App.` }))] }));
}
export default GithubAuthorizationButton;
