import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Body, Link } from '@leafygreen-ui/typography';
import classNames from 'classnames';
import Footer from 'baas-ui/app/footer';
import { TopNav } from 'baas-ui/nav';
import { useDarkMode } from 'baas-ui/theme';
import sadfacegif from 'baas-static/images/sadface.gif';
export var TestSelector;
(function (TestSelector) {
    TestSelector["NotFoundPage"] = "not-found-page";
})(TestSelector || (TestSelector = {}));
const NotFound = ({ showTopNav, showFooter }) => {
    const darkMode = useDarkMode();
    return (_jsxs("div", { children: [showTopNav && _jsx(TopNav, {}), _jsx("div", { "data-testid": TestSelector.NotFoundPage, "data-cy": 'not-found-page', className: classNames('not-found', 'not-found-is-spaced', {
                    'not-found-light-mode': !darkMode,
                    'not-found-dark-mode': darkMode,
                }), children: _jsxs("div", { className: "not-found-error-message-container", children: [_jsxs("div", { className: "not-found-container", children: [_jsx("img", { className: "not-found-image", src: sadfacegif, alt: "a sad computer" }), _jsxs("div", { className: "not-found-code", children: ["{ status: 404, ", _jsx("br", {}), "\u00A0\u00A0message: \"Document Not Found\" }"] })] }), _jsx(Body, { className: "not-found-text", children: "Oops! We can't find the page you were looking for." }), _jsxs(Body, { className: "not-found-text", children: ["If you're experiencing a critical issue, please\u00A0", _jsx(Link, { className: "not-found-text-link", href: "mailto:support-operations@mongodb.com", children: "email support" }), "."] })] }) }), showFooter && _jsx(Footer, {})] }));
};
export default NotFound;
