import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import styled from '@emotion/styled';
import ConfirmationModal, { Variant } from '@leafygreen-ui/confirmation-modal';
import { fontWeights } from '@leafygreen-ui/tokens';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
const StyledConfirmationModal = styled(ConfirmationModal) `
  z-index: ${ZIndex.Modal};
`;
const BoldSpan = styled.span `
  font-weight: ${fontWeights.bold};
`;
export const ExistingTrigger = ({ cancel, proceed, show, triggerName, triggerType }) => {
    const typeDisplayName = triggerType
        .split(' ')
        .map((word) => `${word.charAt(0)}${word.substring(1).toLocaleLowerCase()}`)
        .join(' ');
    return (_jsxs(StyledConfirmationModal, { open: show, title: "Discard changes?", variant: Variant.Danger, cancelButtonProps: {
            onClick: cancel,
        }, confirmButtonProps: {
            children: 'Discard',
            onClick: proceed,
        }, children: ["Are you sure you want to navigate away and discard your changes to ", typeDisplayName, ' ', _jsx(BoldSpan, { children: triggerName }), "?"] }));
};
export default createConfirmation(confirmable(ExistingTrigger));
