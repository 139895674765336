import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useMemo, useState } from 'react';
export const SnippetsContext = createContext({
    inserted: false,
    setInserted: () => { },
});
export const SnippetsContextProvider = ({ children }) => {
    const [inserted, setInserted] = useState(false);
    const memoizedData = useMemo(() => ({
        inserted,
        setInserted,
    }), [inserted]);
    return _jsx(SnippetsContext.Provider, { value: memoizedData, children: children });
};
