import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from '@emotion/styled';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Code from '@leafygreen-ui/code';
import { Body, Description, Label } from '@leafygreen-ui/typography';
import CollapsibleExample from 'baas-ui/common/components/collapsible-example';
import { CodeEditor } from 'baas-ui/functions/code-editor';
import { SupportedLanguages } from 'baas-ui/functions/code-editor/CodeEditor';
import './expression-editor-form-row.less';
const baseClassName = 'expression-editor-form-row';
const RuleExpressionEditor = styled(CodeEditor) `
  height: 200px;
`;
export var TestSelector;
(function (TestSelector) {
    TestSelector["CollapsibleExampleC"] = "collapsible-example";
    TestSelector["DescriptionText"] = "description-text";
    TestSelector["ExampleCode"] = "example-code";
    TestSelector["ExampleDescriptionText"] = "example-text";
    TestSelector["ExpressionEditor"] = "expression-editor";
    TestSelector["LabelC"] = "label";
    TestSelector["ParsingErrorBanner"] = "parsing-error-banner";
})(TestSelector || (TestSelector = {}));
export const ExpressionEditorFormRow = ({ 'data-cy': cyTarget, className, expressionStr, labelText, description, exampleText, exampleCode, onChangeExpression, parsingError, }) => {
    return (_jsxs("div", { className: className, children: [_jsx(Label, { htmlFor: "expression-editor", "data-test-selector": TestSelector.LabelC, "data-testid": TestSelector.LabelC, children: labelText }), _jsx(Description, { "data-test-selector": TestSelector.DescriptionText, "data-testid": TestSelector.DescriptionText, className: `${baseClassName}-description`, children: description }), _jsx(CollapsibleExample, { className: `${baseClassName}-view-example`, "data-test-selector": TestSelector.CollapsibleExampleC, "data-testid": TestSelector.CollapsibleExampleC, children: _jsxs("div", { className: `${baseClassName}-view-example-content-wrapper`, children: [_jsx(Body, { className: `${baseClassName}-view-example-description`, "data-test-selector": TestSelector.ExampleDescriptionText, "data-testid": TestSelector.ExampleDescriptionText, children: exampleText }), _jsx(Code, { language: "json", "data-test-selector": TestSelector.ExampleCode, children: exampleCode })] }) }), _jsx(RuleExpressionEditor, { "data-cy": cyTarget, "data-test-selector": TestSelector.ExpressionEditor, "data-testid": TestSelector.ExpressionEditor, functionInput: expressionStr, language: SupportedLanguages.JSON, onChange: (expression) => onChangeExpression(expression) }), parsingError && (_jsx(Banner, { variant: Variant.Danger, className: `${baseClassName}-parsing-error-banner`, "data-testid": TestSelector.ParsingErrorBanner, children: parsingError }))] }));
};
export default ExpressionEditorFormRow;
