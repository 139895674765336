import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import TextInput from '@leafygreen-ui/text-input';
import Toggle from '@leafygreen-ui/toggle';
import { Body, H3, Label } from '@leafygreen-ui/typography';
import classNames from 'classnames';
import { useDarkMode } from 'baas-ui/theme';
import { track } from 'baas-ui/tracking';
import './collection-explorer.less';
const baseClassName = 'collection-explorer';
var AriaLabel;
(function (AriaLabel) {
    AriaLabel["ToggleEmpty"] = "toggle-empty";
})(AriaLabel || (AriaLabel = {}));
export var TestSelector;
(function (TestSelector) {
    TestSelector["Container"] = "collection-explorer-container";
    TestSelector["DataSourceRowHeader"] = "data-source-header";
    TestSelector["DataSourceRowContent"] = "data-source-content";
    TestSelector["DatabaseRowHeader"] = "database-header";
    TestSelector["DatabaseRowContent"] = "database-content";
    TestSelector["CollectionRowHeader"] = "collection-header";
    TestSelector["CollectionRowLink"] = "collection-row-link";
    TestSelector["SearchInput"] = "search-input";
    TestSelector["UnconfiguredToggle"] = "toggle-unconfigured";
    TestSelector["DefaultConfigButtonLink"] = "default-config-button-link";
    TestSelector["PreventNavigationModal"] = "prevent-navigation-modal";
})(TestSelector || (TestSelector = {}));
export var ResourceName;
(function (ResourceName) {
    ResourceName["Schema"] = "schema";
    ResourceName["Rules"] = "rules";
})(ResourceName || (ResourceName = {}));
export default function CollectionExplorer({ className, appUrl, context, resourceName, dataSourceMenu: DataSourceMenu, isCollectionConfigured, databaseMenu: DatabaseMenu, collectionMenu: CollectionMenu, collectionRowLink, defaultConfigButton: DefaultConfigButton, shouldPreventNavigation, defaultConfigButtonLink = () => '', onUnconfiguredToggleChange = () => { }, syncConfig, dataSourceDetailElement = () => { }, collectionDetailElement = () => { }, }) {
    const { dataSources, selectedDataSourceData, selectedDatabaseData, selectedCollectionData, setSelectedNamespace } = useContext(context);
    const [searchValue, setSearchValue] = React.useState('');
    const [showEmpty, setShowEmpty] = React.useState(true);
    const [collapsedDataSources, setCollapsedDataSources] = React.useState(new Set());
    const [collapsedDatabases, setCollapsedDatabases] = React.useState(new Set());
    const [showPreventNavigationModal, setShowPreventNavigationModal] = React.useState(false);
    const [selectedCollectionKey, setSelectedCollectionKey] = React.useState();
    function handleToggleDataSource(dataSourceName) {
        return (e) => {
            if (e.target !== e.currentTarget) {
                return;
            }
            const newDataSources = new Set([...collapsedDataSources]);
            if (newDataSources.has(dataSourceName)) {
                newDataSources.delete(dataSourceName);
            }
            else {
                newDataSources.add(dataSourceName);
            }
            setCollapsedDataSources(newDataSources);
        };
    }
    function handleToggleDatabase(databaseKey) {
        return (e) => {
            if (e.target !== e.currentTarget) {
                return;
            }
            const newDatabases = new Set([...collapsedDatabases]);
            if (newDatabases.has(databaseKey)) {
                newDatabases.delete(databaseKey);
            }
            else {
                newDatabases.add(databaseKey);
            }
            setCollapsedDatabases(newDatabases);
        };
    }
    const darkMode = useDarkMode();
    return (_jsxs("div", { className: `${baseClassName} ${className}`, "data-testid": TestSelector.Container, children: [_jsxs(ConfirmationModal, { className: `${baseClassName}-discard-changes-modal`, open: showPreventNavigationModal, confirmButtonProps: {
                    children: 'OK',
                    onClick: () => {
                        setShowPreventNavigationModal(false);
                        selectedCollectionKey && setSelectedNamespace(selectedCollectionKey);
                    },
                }, cancelButtonProps: {
                    onClick: () => {
                        setShowPreventNavigationModal(false);
                        setSelectedCollectionKey('');
                    },
                }, title: `Discard changes to ${resourceName}`, variant: "danger", "data-testid": TestSelector.PreventNavigationModal, "data-cy": `${baseClassName}-prevent-navigation-modal`, children: ["Are you sure you want to navigate away and discard your changes to ", resourceName, "?"] }), _jsxs("div", { className: "view-controls", children: [_jsxs("div", { className: "view-controls__row", children: [_jsx(TextInput, { className: "view-controls__input", "aria-labelledby": "search-input", value: searchValue, onChange: (e) => setSearchValue(e.target.value), placeholder: "Search for collection", "data-test-selector": TestSelector.SearchInput }), _jsx(Icon, { className: "view-controls__icon", glyph: "MagnifyingGlass" })] }), _jsxs("div", { className: "view-controls__row", children: [_jsxs(Label, { className: "view-controls__label", htmlFor: AriaLabel.ToggleEmpty, id: AriaLabel.ToggleEmpty, children: ["Show collections without ", resourceName === ResourceName.Schema ? 'a ' : '', resourceName] }), _jsx(Toggle, { "data-cy": TestSelector.UnconfiguredToggle, "data-test-selector": TestSelector.UnconfiguredToggle, className: "view-controls__toggle", id: AriaLabel.ToggleEmpty, checked: showEmpty, onChange: (isChecked) => {
                                    setShowEmpty(isChecked);
                                    onUnconfiguredToggleChange(isChecked);
                                }, size: "xsmall", "aria-labelledby": AriaLabel.ToggleEmpty })] })] }), _jsx("ul", { className: `${baseClassName}-list`, children: dataSources.map(({ dataSourceId, dataSourceName, databases, namespace: dataSourceKey, hasCollectionData }) => {
                    const isDataSourceSelected = selectedDataSourceData?.dataSourceName === dataSourceName;
                    return (_jsxs("li", { className: `${baseClassName}-list-item`, "data-cy": `${baseClassName}-data-source`, children: [_jsxs("div", { "data-cy": `data-source-header-${dataSourceKey}`, "data-test-selector": `${TestSelector.DataSourceRowHeader}-${dataSourceKey}`, "data-testid": `${TestSelector.DataSourceRowHeader}-${dataSourceKey}`, className: classNames(`${baseClassName}-list-item__content ${baseClassName}-list-item__content--top-level`, {
                                    [`${baseClassName}-list-item__content--collapsed`]: collapsedDataSources.has(dataSourceName),
                                    [`${baseClassName}-list-item__content-light-mode`]: !darkMode,
                                    [`${baseClassName}-list-item__content-dark-mode`]: darkMode,
                                }), onClick: handleToggleDataSource(dataSourceKey), children: [_jsx(Icon, { className: `${baseClassName}-list-item__toggle`, glyph: collapsedDataSources.has(dataSourceName) ? 'ChevronRight' : 'ChevronDown', size: "small", onClick: handleToggleDataSource(dataSourceKey) }), _jsx(H3, { className: classNames(`${baseClassName}-list-item__title`, {
                                            [`${baseClassName}-list-item__title--selected`]: isDataSourceSelected,
                                        }), onClick: handleToggleDataSource(dataSourceKey), children: dataSourceName }), dataSourceDetailElement && dataSourceDetailElement(dataSourceId), _jsx(DataSourceMenu, { appUrl: appUrl, dataSourceId: dataSourceId, dataSourceName: dataSourceName, setSelectedNamespace: () => setSelectedNamespace(dataSourceName), hasCollectionData: hasCollectionData, trigger: _jsx(IconButton, { className: `${baseClassName}-list-item__menu-trigger`, "aria-label": "Data Source Options", "data-cy": "data-source-menu-trigger", children: _jsx(Icon, { glyph: "Ellipsis" }) }), syncConfig: syncConfig })] }), !collapsedDataSources.has(dataSourceName) && (_jsxs("ul", { className: `${baseClassName}-list`, "data-test-selector": `${TestSelector.DataSourceRowContent}-${dataSourceKey}`, children: [!!DefaultConfigButton && (_jsx(Link, { className: `${baseClassName}-additional-rows--link`, to: defaultConfigButtonLink(dataSourceName), "data-cy": `${baseClassName}-additional-rows-link-${dataSourceName}`, "data-test-selector": `${TestSelector.DefaultConfigButtonLink}-${dataSourceName}`, children: _jsx("div", { className: `${baseClassName}-additional-rows`, children: _jsx(DefaultConfigButton, { dataSourceId: dataSourceId, dataSourceName: dataSourceName, updateCurrentDataSource: () => {
                                                    setSelectedNamespace(dataSourceName);
                                                    setSelectedCollectionKey(undefined);
                                                } }) }) }, dataSourceId)), databases.map(({ databaseName, collections, namespace: databaseKey, hasCollectionData: dbHasCollectionData }) => {
                                        const isDatabaseSelected = isDataSourceSelected && selectedDatabaseData?.databaseName === databaseName;
                                        const headerTestSelector = `${TestSelector.DatabaseRowHeader}-${databaseKey}`;
                                        return (_jsxs("li", { "data-cy": `${baseClassName}-database-${databaseName}`, children: [_jsxs("div", { "data-test-selector": headerTestSelector, "data-testid": headerTestSelector, className: classNames(`${baseClassName}-list-item__content ${baseClassName}-list-item__content--middle-level`, {
                                                        [`${baseClassName}-list-item__content-light-mode`]: !darkMode,
                                                        [`${baseClassName}-list-item__content-dark-mode`]: darkMode,
                                                    }), onClick: handleToggleDatabase(databaseKey), children: [_jsx(Icon, { className: `${baseClassName}-list-item__toggle`, glyph: collapsedDatabases.has(databaseKey) ? 'ChevronRight' : 'ChevronDown', size: "small", onClick: handleToggleDatabase(databaseKey) }), _jsx(Body, { className: classNames(`${baseClassName}-list-item__title`, {
                                                                [`${baseClassName}-list-item__title--selected`]: isDatabaseSelected,
                                                            }), onClick: handleToggleDatabase(databaseKey), children: databaseName }), _jsx(DatabaseMenu, { dataSourceName: dataSourceName, databaseName: databaseName, hasCollectionData: dbHasCollectionData })] }), !collapsedDatabases.has(databaseKey) && (_jsx("ul", { className: `${baseClassName}-list`, "data-test-selector": `${TestSelector.DatabaseRowContent}-${databaseKey}`, children: collections.map((collectionData) => {
                                                        const { collectionName, namespace: collectionKey } = collectionData;
                                                        const isCollectionSelected = isDataSourceSelected &&
                                                            isDatabaseSelected &&
                                                            selectedCollectionData?.collectionName === collectionName;
                                                        if (!isCollectionConfigured(collectionData) && !showEmpty) {
                                                            return null;
                                                        }
                                                        if (searchValue && !collectionName.startsWith(searchValue)) {
                                                            return null;
                                                        }
                                                        return (_jsx(Link, { "data-testid": `${TestSelector.CollectionRowLink}-${collectionKey}`, className: `${baseClassName}-list-item--link`, to: collectionRowLink(collectionData), onClick: (e) => {
                                                                if (shouldPreventNavigation) {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }
                                                            }, "data-cy": `${baseClassName}-collection-${collectionName}`, children: _jsx("li", { "data-cy": `collection-header-${dataSourceName}-${databaseName}-${collectionName}`, "data-test-selector": `${TestSelector.CollectionRowHeader}-${collectionKey}`, "data-testid": `${TestSelector.CollectionRowHeader}-${collectionKey}`, onClick: (e) => {
                                                                    if (collectionKey === selectedCollectionKey) {
                                                                        return;
                                                                    }
                                                                    setSelectedCollectionKey(collectionKey);
                                                                    if (shouldPreventNavigation) {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        setShowPreventNavigationModal(true);
                                                                    }
                                                                    else
                                                                        setSelectedNamespace(collectionKey);
                                                                }, children: _jsxs("div", { className: classNames(`${baseClassName}-list-item__content 
                                          ${baseClassName}-list-item__content--bottom-level`, {
                                                                        [`${baseClassName}-list-item__content-light-mode`]: !darkMode,
                                                                        [`${baseClassName}-list-item__content-dark-mode`]: darkMode,
                                                                    }), children: [_jsx(Body, { className: classNames(`${baseClassName}-list-item__title`, {
                                                                                [`${baseClassName}-list-item__title--selected`]: isCollectionSelected,
                                                                                [`${baseClassName}-list-item__title--unconfigured`]: !isCollectionConfigured(collectionData),
                                                                            }), children: collectionName }), collectionDetailElement && collectionDetailElement(collectionData), _jsx(CollectionMenu, { appUrl: appUrl, collectionData: collectionData, setSelectedNamespace: () => setSelectedNamespace(collectionKey), onTriggerClick: (e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                switch (resourceName) {
                                                                                    case ResourceName.Rules:
                                                                                        track('RULES_CONFIGURATION.CONFIGURE_COLLECTION_VIEWED');
                                                                                        break;
                                                                                    case ResourceName.Schema:
                                                                                        track('SCHEMAS.CONFIGURE_COLLECTION_VIEWED');
                                                                                        break;
                                                                                    default:
                                                                                        break;
                                                                                }
                                                                            } })] }) }) }, collectionKey));
                                                    }) }))] }, databaseKey));
                                    })] }))] }, dataSourceKey));
                }) })] }));
}
